










































































































import { Component, Vue } from 'vue-property-decorator';
import { required, minLength } from 'vuelidate/lib/validators';
import { passwordRegex } from '@shared/services/validators';
import GoogleLogin from 'vue-google-login';
import ShowContent from '@shared/components/ShowContent.vue';
Component.registerHooks(['validations']);

@Component({
  components: {
		GoogleLogin,
		ShowContent
  }
})
export default class Login extends Vue {
  $v: any;
  loginFailed = false;
  form = {
    username: null,
    password: null
  }

  params = {
    client_id: process.env.VUE_APP_SSO_CLIENT_ID_GOOGLE
  };

  onSuccess(googleResponse: any) {
    this.$emit('setOverlay', true);
    console.log('google success', googleResponse);
    this.loginFailed = false;
    this.$store.dispatch('auth/googleLogin', googleResponse.getAuthResponse().id_token)
      .then(() =>  this.goToPortal(),
      () => this.loginError());
  }

  onFailure(test: any) {
    console.log(test);
    this.loginError();
  }

  created() {
    if(this.$store.state.auth.loggedIn) {
      this.goToPortal();
    }
  }

  validations() {
    return {
      form: {
        username: {
          required
        },
        password: {
          required,
          passwordRegex,
          minLength: minLength(6),
        }
      }
    };
  }

  validateState(name: string|number) {
    const { $dirty, $error } = this.$v.form[name] || {};
    return $dirty ? !$error : null;
  }

  resetForm() {
    this.form = {
      username: null,
      password: null
    };

    this.$nextTick(() => {
      this.$v.$reset();
    });
  }

  onSubmit() {
    this.$v.form.$touch();
    if (this.$v.form.$anyError) {
      // return;
    }
    this.$emit('setOverlay', true);
    this.loginFailed = false;
    this.$store.dispatch('auth/login', { username: this.form.username, password: this.form.password})
      .then(() =>  {
        this.goToPortal();
      },
      () => {
        this.loginError();
      });
  }

  goToPortal() {
    this.$emit('setOverlay', false);
    if(this.$route.query.redirect) {
      this.$router.push(this.$route.query.redirect.toString());
    }
    else {
      console.log('Redirect from login screen');
      this.$router.push('/portal');
    }
  }

  loginError() {
    this.loginFailed = true;
    this.$emit('setOverlay', false);
  }
}
