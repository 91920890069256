export const passwordRegex = (value: string) => {
  return /^.*[0-9].*$/.test(value) && /^.*[a-z].*$/.test(value) && /^.*[A-Z].*$/.test(value) && /^.*\W$/.test(value);
};

export const between0and100 = (value: number) => {
  return value > -1 && value < 101;
};

export const phoneValidation = (value: string) => {
  return /\(\d{3}\) \d{3}-\d{4}/g.test(value);
};

// export const phoneFormat = (value: string) => {
//   const cleaned = ('' + value).replace(/\D/g, '');
//   const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     return '(' + match[1] + ') ' + match[2] + '-' + match[3];
//   }
//   return value;
// };
export const phoneFormat = (value: string) => {
  const match = value?.replace(/\D+/g, '')?.replace(/^1/, '')?.match(/([^\d]*\d[^\d]*)*$/);
  if(match) {
    const x = match[0];
    const part1 = x.length > 2 ? `(${x.substring(0,3)})` : x;
    const part2 = x.length > 3 ? ` ${x.substring(3, 6)}` : '';
    const part3 = x.length > 6 ? `-${x.substring(6)}` : '';
    return `${part1}${part2}${part3}`;
  }
  return value;
};


export const getAge = (value: Date) => {
  const today = new Date();
  let age = today.getFullYear() - value.getFullYear();
  if(today.getMonth < value.getMonth || (today.getMonth === value.getMonth && today.getDay < value.getDay)) {
    age--;
  }
  return age;
};
